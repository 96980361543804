export const projects = [
  {
    title: "TRANQUILITY APP",
    subtitle: "CSS, HTML, JavaScript, External API",
    description: "The Tranquility app was created to give people an opportunity to take a break from the everyday demand in a fast paced enviroment. It allows the user to press a button that will generate a random inspirational quote, as well as a random picture of nature. I also added a journaling feature, where the user can journal some thoughts which will be saved in their local storage.",
    image: "./tranquility_updated_speed.gif",
    link: "https://kimcapgab.github.io/Tranquility_App/",
    github: "https://github.com/kimcapgab/Tranquility_App",
    id: "1",
  },
  {
    title: "SHOP ECO APP",
    subtitle: "React, Airtable API, Material UI ",
    description: "Shop Eco is an Airtable and React build where the user is able to browse a variety of eco-friendly small businesses. At the homepage, the user is able to navigate shops based on the categories (Furniture, Clothing, and Candles). Each page will lead the user to eco-friendly shops that fit the category that was selected. The user is also able to submit a new shop that they would like to add to the existing list.",
    image: "./ShopEcogif.gif",
    link: "https://shop-eco.netlify.app/",
    github: "https://github.com/kimcapgab/shop-eco-app",
    id: "2",
  },
  {
    title: "YOUR CRAFT APP",
    subtitle: "React, Express, Mongoose, MongDB, Full CRUD",
    description: "Your Craft was created as a group project. It is an app that displays an inventory of craft beer, wines and liquors. This app was created to promote small locally owned Brewers, Distillers, and Wine Makers. It allows small businesses to add their products, a link to their website, as well as show details about their beverages. Please use the following credentials if you would like to test the creation and editing of beverages without creating an account. Username: admin Password: password",
    image: "https://res.cloudinary.com/dn2x2ldxj/image/upload/v1644441433/ezgif.com-gif-maker_tmvox9.gif",
    link: "https://your-craft.netlify.app/",
    github: "https://github.com/kimcapgab/your-craft",
    id: "3",
  },
  {
    title: "GARDEN ZONE",
    subtitle: "Postgres, Django, Python and React", 
    description: "Garden Zone is an app where the user is able to select what zone they live in, and the app will generate what vegetables are best to grow in the selected zone. The app will also allow users to create an account and must be logged in to add new vegetables and make any edits to vegetables. Please use the following credentials if you would like to test the creation and editing of the vegetables without creating an account. Username: admin Password: password",
    image: "https://res.cloudinary.com/dn2x2ldxj/image/upload/v1644376266/Garden_Zone_ubbofb.gif",
    link: "https://relaxed-tereshkova-af5914.netlify.app/",
    github: "https://github.com/kimcapgab/garden-zone",
    id: "4",
  },
]